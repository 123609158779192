import { isDevEnv } from '@/app/src/app/config/env'
import { YMId } from '../constants'

const consentModeDefault = {
  security_storage: 'granted',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  personalization_storage: 'denied',
  functionality_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
}

export const analyticsApi = {
  sendFormSuccess: () => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'send-form-success')
  },

  sendFormError: () => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'send-form')
  },

  sendCookieConcentDefault: (value?: unknown) => {
    if (isDevEnv) return

    gtag('consent', 'default', value ?? consentModeDefault)
  },

  sendCookieConcent: (value: unknown) => {
    if (isDevEnv) return

    gtag('consent', 'update', value)
  },

  clickClickableCase: (link: string | null) => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'yes-clickable-case')
    dataLayer.push({ event: 'yes-clickable-case', slugOrUrl: link })
  },

  clickNonClickableCase: (title: string) => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'non-clickable-case')
    dataLayer.push({ event: 'non-clickable-case', caseName: title })
  },

  sendEliseDecamp: () => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'elisedecamp')
  },

  openCaseFaq: () => {
    if (isDevEnv) return

    ym(YMId, 'reachGoal', 'faq-open')
  },

  pageview: (url: string) => {
    if (isDevEnv) return

    // @ts-ignore
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  },
}
