/* eslint-disable react-hooks/exhaustive-deps */
import { YMId, GtagId } from '../constants'
import { useEffect, useRef, useState } from 'react'
import { isLightHouse } from '@/app/src/shared/lib/detectDevice'
import { isDevEnv } from '@/app/src/app/config/env'
import { initGtagScript, initYandexMetricaScript } from '../scripts'
import { useSearchParams } from 'next/navigation'
import { analyticsApi } from '..'

export const useYandexMetrica = ({ metricaId = YMId } = {}) => {
  const loadedMetrica = useRef<boolean>(false)
  const searchParams = useSearchParams()

  const loadMetrica = () => {
    if (loadedMetrica.current) return

    initYandexMetricaScript(metricaId)
    loadedMetrica.current = true
  }

  useEffect(() => {
    if (isLightHouse || isDevEnv) return

    loadMetrica()
  }, [])

  useEffect(() => {
    if (searchParams?.toString().includes('part=elisedecamp')) {
      analyticsApi.sendEliseDecamp()
    }
  }, [searchParams])

  return { metricaId }
}

export const useGtag = ({ gtagId = GtagId } = {}) => {
  const [loadedGtag, setStateLoaded] = useState(false)

  const loadGtag = () => {
    if (loadedGtag) return

    initGtagScript(gtagId)
    setStateLoaded(true)
  }

  useEffect(() => {
    if (isLightHouse || isDevEnv) return

    loadGtag()
  }, [])

  return { gtagId, isInitGtag: loadedGtag }
}
